svg{
  width: 20px;
  height: 25px;
}
.item {
  background-color: #eeeeee;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  /* min-width: 200px;
  max-width: 600px; */
  width: 400px;
}
.item-p {
  margin: 20px;
  flex-grow: 1;
}
.icon {
  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;
}

.item-check--active {
  background-color: #c2c2c2;
  box-shadow: 0px 5px 50px
    rgba(32, 35, 41, 0.15);
}

.icon-check--active {
  color: green;
}
.icon-check:hover {
  color: green;
}

.icon-delete:hover {
  color: red;
}
.icon-edit svg:hover {
  color: blue;
}
