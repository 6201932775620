.containerModal{
    background-color:#d4d4d4;
    border-radius: 5px;
    width: 400px;
    height: 320px;
    padding: 25px;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}
h2{
    /* justify-content: center; */
/* align-items: center; */
align-self: center;
}

.modal-title{
    font-size:larger;       
}
.modal-textarea{
    border-radius: 5px;
    flex-grow: 1;
    padding: 20px;
    resize: none;
    height: 100px;
    text-align: center;
}

.buttonContainer{
    height: 50px;
    margin-top: 25px;
    display: flex;
    justify-content: space-evenly;
}
.buttonContainer button{
    border-radius: 5px;
    border: none;
    width: 120px;
}
.buttonContainer button:last-child{
    /* background-color: #2da12d; */
    background-color: #3eb13e;
}

.containerForm{
    padding: 0;
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
}