body {
  /* display: flex;
  justify-content: center;
  flex-basis: 100vmax; */
  padding: 0;
  margin: 0;
  background: #d4d4d4;
  font-family: -apple-system,
    BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue",
    sans-serif;
}
#root {
  margin: 0 24px;
  position: relative;
  /* height: 100vh; */
}
.title {
  text-align: center;
}

.button-modal{
  z-index: 100;
  background-color: #3eb13e;
  font-size: 35px;
  border-radius: 50%;
  position: fixed;
  border: none;
  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;
}
.close-modal{
  transform: rotate(45deg);
}
 button{
   cursor: pointer;
   text-decoration: none;

  
 }